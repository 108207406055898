.App {
  text-align: center;
  min-height: 100vh;
}

@font-face {

  font-family: "UNFPA-Bold";

  src: url("Fonts/UNFWBDRM.ttf") format("truetype");

  font-weight: normal;

  font-style: normal;

  -webkit-font-smoothing: antialiased;

  -moz-osx-font-smoothing: grayscale;

}




@font-face {

  font-family: "UNFPA-Light";

  src: url("Fonts/UNFWLTRM.ttf") format("truetype");

  font-weight: normal;

  font-style: normal;

  -webkit-font-smoothing: antialiased;

  -moz-osx-font-smoothing: grayscale;

}




@font-face {

  font-family: "UNFPA-Regular";

  src: url("Fonts/UNFWTXRM.ttf") format("truetype");

  font-weight: normal;

  font-style: normal;

  -webkit-font-smoothing: antialiased;

  -moz-osx-font-smoothing: grayscale;

}

body,
* {
  font-family: "UNFPA-Regular" !important;
}

.hidden{
  display: none;
}

.MuiAppBar-root .MuiTypography-root.MuiTypography-h6 {
  font-family: "UNFPA-Bold" !important;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

table {
  border-collapse: collapse;
  border-spacing: 0;
  width: 100%;
  border: 1px solid #ddd;
}

table th,
table td {
  text-align: left;
  padding: 16px;
  border: 1px solid #ddd;
}

table tr:nth-child(even) {
  background-color: #f2f2f2;
}

table tr:hover {
  background-color: #ddd;
}

table th {
  padding-top: 12px;
  padding-bottom: 12px;
  text-align: center;
  background-color: #333847;
  color: white;
}

.text-left {
  text-align: left;
}

.Logodiv {
  background-image: url("./Assets/logo.svg");
  height: 30px;
  width: 300px;
  background-position: left;
  background-size: contain;
  background-repeat: no-repeat;
  /* margin: 0 auto; */
}

.Statedrop {
  text-align: right;
  padding-right: 16px;
  margin-top: 30px;
}

.DialogDdrop {
  text-align: center;
  padding-right: 16px;
  margin-top: 30px;
}

.MuiInputBase-root {
  min-width: 200px;
  text-align: left;
}

.Logocontainer {
  height: calc(100vh - 50px);
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #fff;
}

.Themetabs {
  padding: 0 40px 0 40px;

  max-width: 80%;
  margin: 0 auto;
  margin-top: 40px;
  margin-bottom: 40px;
  min-height: calc(100vh - 310px);
}

.Themetabs .MuiBox-root {
  border: 1px solid #eff1f3;
  -webkit-box-shadow: 0px 1px 7px rgba(0, 0, 0, 0.1);
  box-shadow: 0px 1px 7px rgba(0, 0, 0, 0.1);
  background-color: #fff;
  border-radius: 6px;
  padding: 10px 20px;
}

.MainContainer {
  background-color: #faf8f8;
  padding-top: 100px !important;
  min-height: 100vh;
}

.MainContainer .demo-simple-select,
.Statedrop .MuiSelect-select {
  background-color: #fff !important;
}

.MuiAppBar-root {
  background-color: #333847 !important;
}

.viewbtn {
  margin-top: 30px !important;
  background: #fff !important;
  color: #333847 !important;
  border-color: #333847 !important;
}

.signoffbtn{
  margin-top: 30px !important;
  background: #fff !important;
  color: #333847 !important;
  border-color: #333847 !important;
  margin-bottom: 40px !important;
}

.submitbtn {
  margin-top: 30px !important;
  background: #259af4 !important;
  color: #fff !important;
  width: 80%;
}

.Mui-selected {
  color: #333847 !important;
}

.MuiTabs-indicator {
  background-color: #333847 !important;
}


.defaultdialog .MuiBackdrop-root {
  width: 100%;
  margin-left: auto;
}

.defaultdialog .MuiDialog-container {
  width: 50%;
  height: 40%;
  margin-top: 15%;
  margin-right: 350px;
  margin-left: 350px;
}

.dialogtitle {
  padding-left: 40px;
}

.mt-0 {
  margin-top: 0px !important;
}

.vert-center {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.formdiv {
  border-bottom: 1px solid #ddd;
  margin-bottom: 10px;
  padding-bottom: 10px;
}

.formdiv:last-child {
  border-bottom: none !important;
}

a:-webkit-any-link {
  color: #333847 !important;
  text-decoration: none !important;
}

.dbbutton {
  background-color: transparent !important;
  color: #fff !important;
}

.dbbutton a {
  color: #fff !important;
}

.formtitle {
  box-shadow: rgba(0, 0, 0, 0.2) 0px 2px 4px -1px, rgba(0, 0, 0, 0.14) 0px 4px 5px 0px, rgba(0, 0, 0, 0.12) 0px 1px 10px 0px;
  background-color: #333847 !important;
  margin: 0px 0px 0px 0px;
  font-weight: 500;
  font-size: 1.25rem;
  line-height: 1.6;
  letter-spacing: 0.0075em;
  font-family: "UNFPA-Bold" !important;
  color: #fff;
  text-align: left;
  padding: 15px;
}

.formsubtitle {
  text-align: left;
  margin-bottom: 20px;
  margin-left: 35px;
}

.loginfielddiv {
  margin-bottom: 25px;
}

.loginfielddiv .MuiTextField-root {
  width: 80%;
}

.mt-10px {
  margin-top: 10px !important;
}

.loginpagemaindiv {
  align-items: center;
  justify-content: center;
  padding: 40px 75px;
  min-height: calc(100vh - 172px) !important;

}

.formviewmaindiv{
  min-height: calc(100vh - 172px) !important;
}

.loginpage {
  background-image: url("Assets/loginbg.png");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  margin-left: 0px;
  min-height: 100vh;
}

.loginpagemaindiv .MuiCard-root {
  background: rgba(51, 56, 71, .8);
  color: #fff;
  max-width: 400px;
}

.loginfielddiv input {
  color: #fff;
  color: #000;
  background: #fff !important;
  border-radius: 4px !important;
  padding: 9px 14px;
}

.loginfielddiv label {
  color: #000;
}

.loginpagemaindiv label.Mui-focused {
  color: #000 !important;
}

fieldset .MuiOutlinedInput-notchedOutline {
  border-color: #fff;
  background: rgba(51, 56, 71, .8) !important;
}

.loginpagemaindiv .Mui-focused .MuiOutlinedInput-notchedOutline {
  border-color: #fff !important;
}

input:-internal-autofill-selected {
  background-color: rgba(51, 56, 71, .8) !important;
  appearance: none !important;
}

/* Change the white to any color */
input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  -webkit-box-shadow: 0 0 0 30px white inset !important;
}

.thematictab {
  margin-top: 120px !important;
}

.loader {
  display: block !important;
}

.signoffloader{
  display: block !important;
  height: calc(100vh - 150px);
}

.leftdrawer button {
  background-color: #333847 !important;
  width: 90%;
  margin: 10px auto;
  margin-left: 5%;
}

.leftdrawer h6 {
  text-align: center;
}

#superset-container iframe {
  width: 100%;
  height: calc(100vh - 150px);
  /* margin-top: 110px; */
}

.dashboardNav{
  margin-top: 110px;
}

.MuiFormHelperText-root {
  color: #ff0000 !important;
  font-weight: 700 !important;
  font-size: 1rem !important;
}

.logobar {
  padding: 8px 24px;
  background-color: #fff;
  display: flex;
}

.logobar .Logodiv {
  height: 40px;
  width: 80px;
}

.logo-name {
  margin-left: 15px;
  color: #9f9e9b;
  font-size: 1.07em;
  margin-top: 0px;
  margin-bottom: 0px;
  padding-top: 10px;
}

.applicationtitle {
  font-size: 28px;
  margin-top: 0px;
}

.footerbg {
  background: #3f3f3f;
  padding: 10px 75px;
  display: flex !important;
  align-items: center;
  justify-content: center;
  padding-bottom: 10px;
  position: fixed;
  left: 0;
  bottom: 0;
  width: 100% !important;
  margin: 0% !important;
  color: white;
  text-align: center;
}

.footerlogo {
  background-image: url("Assets/new_logo_footer.png");
  width: 110px;
  height: 50px;
  background-repeat: no-repeat;

}

.copyright {
  color: #767676;
  font-size: 1em;
  line-height: 20px;
  float: left;
  padding: 15px 0 0 0;
  margin: 0px !important;
}



.sociallogoholder {
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  color: #fff;
}

.pt-0px {
  padding-top: 0px !important;
}

.donatelogo {
  background-image: url("Assets/icon_donate_black.svg");
  width: 30px;
  height: 25px;

  display: inline-block;
  margin-right: 10px;
  background-repeat: no-repeat;
}

.donatelink {
  background: #fff;
  color: #000 !important;
  display: flex;
  align-items: center;
  justify-content: center;
  width: fit-content;
  margin: 0 auto;
  padding: 10px 20px;
  border-radius: 5px;
}

.donatetext {
  display: inline-block;
  margin-top: -5px;
}

.footerbg a svg {
  color: #fff !important;
}

.Formviewdialog {
  width: 100%;
  height: 1000px;
}

.Formviewdialog .MuiDialog-container {
  height: 85vh;
  width: 80vw;
  margin: 0 auto;
  margin-top: 5vh;
}

/* input:-webkit-autofill{
  -webkit-text-fill-color: transparent !important;
} */

.profilebtn{
  background-color: #ffb765 !important;
  width: 38px !important;
  height: 38px;
  border-radius: 50% !important;
  min-width: 32px !important;
  color: #fff !important;
}

.profmenubtn{
  color: #565e76 !important;
  cursor: pointer !important;
  font-size:14px !important;
}

.profusrname{
  color: #565e76 !important;
  font-size:18px !important;
  padding: 6px 16px !important;
  margin: 0px !important;
  font-weight: bold;
}

.profusremail{
  color: #565e76 !important;
  font-size:14px !important;
  padding: 6px 16px !important;
  margin: 0px !important;
  margin-bottom: 0px !important;
  padding-bottom: 18px !important;
}

.profilecontainer{
  border-bottom: 1px solid #ddd;
  margin-bottom: 10px;
}

.profmenu .MuiPaper-root{
  min-width: 300px;
}

.proficon{
  background-color: #ffb765 !important;
  width: 48px !important;
  height: 48px;
  border-radius: 50% !important;
  min-width: 32px !important;
  color: #fff !important;
  display: flex;
    align-items: center;
    justify-content: center;
    margin-left: 16px;
    font-size: 26px;
  
}

.proficondiv{
  margin-top: -15px;
}

.proficon span{
  display: inline-block;
    margin-top: -8px;
}

/* .thematictabbody{
  min-height: calc(100vh - 200px) !important;
} */

.navbardiv{
  display: flex !important;
  align-items: center !important;
  justify-content: center !important;
}

.navbardiv button.active a , .navbardiv button.active span,.signoffmenu button.active a{
  border-bottom: 1px solid #fff;
}

.navbardiv button.active a span.MuiTouchRipple-root, .navbardiv button.active span.MuiTouchRipple-root,
.navbardiv button.active .MuiTouchRipple-root, .navbardiv button.active a span{

  border-bottom: none;
}

#superset-container{
    height: calc(100vh - 24px);
    overflow-y: auto;
    margin-bottom: 0px;
}

.tablebtn{
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-evenly;
}

.modalsbmbtn{
  margin-top: 30px !important;
  background: #282c34 !important;
  color: #fff !important;
  width: 20%;
  margin: auto !important;
}

.secondarynavbar{
  min-height: 55px !important;
  height: 55px;
  padding-left: 9px !important;
}

.secondarynavbar p{
  margin: 0px !important;
  font-size: 16px !important;
}

.secondarynavbar ul{
  padding: 0px !important;
}

.secondarynavbar button{
  padding: 5px 8px !important;
}

.resetpassmaindiv{
  align-items: center;
    justify-content: center;
    padding: 40px 75px;
    min-height: calc(100vh - 128px) !important;
    padding-top: 0px;
    margin-top: -60px !important;
}

.resetpassmaindiv .MuiPaper-root{
  max-width: 400px;
}

.signoffpg .MuiPaper-root{
  box-shadow: none;
}

.signoffpg .MuiAccordion-root{
  margin-bottom: 15px;
}

.heatmaptableholder{
  overflow-x: auto;
}

.fw-bold{
  font-weight: bold;
}

.titleorange{
  color: #eb7e00;
}

.fs-14px{
  font-size: 14px;
}

.fs-13px{
  font-size: 13px;
}

.cell-yellow{
  background-color: #ffc100;
}

.cell-green{
  background-color: #92d051;
}

.heatmaptableholder{
  margin: 15px 0px 30px 0px;
}

.heatmaptableholder {
  overflow-y: auto;
  height: calc(100vh - 350px);
}
.heatmaptableholder thead th {
  position: sticky;
  top: 0;
}

.heatmaptableholder thead tr.secondline th {
  position: sticky;
  top: 50px;
} 
.heatmaptableholder table {
  border-collapse: collapse;        
  width: 100%;
}

.progressoverviewpage{
  height: calc(100vh - 24px);
  min-height: calc(100vh - 24px);
  overflow: hidden;
}

.filtercontrolholder{
  display: flex;
    align-items: flex-end;
    justify-content: flex-end;
    padding: 15px 24px;
    padding-bottom: 0px;
}

.filtercontrolholder .MuiFormControl-root{
  margin-left: 15px;
}

.signoffpg table{
  margin-top: 10px;
}

.mnesignoff table{
  margin-top: 10px;
}

.signoffpg table th {
  padding-top: 12px;
  padding-bottom: 12px;
  text-align: center;
  background-color: #333847;
  color: white;
  text-transform: uppercase;
  font-weight: bold;
}

.signoffpg .MuiTabs-root{
  margin-top: 100px;
}

.signoffpg thead  {
  position: sticky;
}

.editSection{
  display:flex;
  flex-direction: column;
}

.editdialoglabel{
  margin: 15px 0px !important;
  color: rgb(235, 126, 0) !important;
  font-weight: 700 !important;
}

.editdialoglabel1{
  margin: 15px 0px !important;
}

.editdialogtxt{
  margin: 5px 0px !important;
}

.editSection button{
  background: #333847;
    color: #fff;
    width: 30px;
    height: 30px;
    min-width: 30px;
    margin: 0px;
    margin-right: 5px;
    margin-top: 5px;
}

.editSection button svg{
  font-size: 14px;
}

.editSection button:hover{
  background: #333847;
}

.editSection{
  flex-direction: row;
}

.Commenttooltip .Mui-disabled {
  color: #fff !important;
  -webkit-text-fill-color:#fff !important
}

.MuiDialog-container h2.MuiDialogTitle-root{
  background: #1a1c24;
    color: #fff;
}

.editdatabtn{
  background: #4CAF50 !important;
    color: #fff !important;
}

.MuiDialogActions-root{
  padding-bottom: 20px;
}

.cancelbtn{
  background: #F44336 !important;
    color: #fff !important;
}

.MuiTooltip-popper .MuiTooltip-tooltip{
  font-size: 14px !important;
}

.progressoverviewpage .MuiTableContainer-root{
  max-height: calc(100vh - 50px);
}

.progressoverviewpage tbody tr td{
  vertical-align: top;
}

.progressoverviewpage tbody tr td.numberholder{
  text-align: center;
  font-weight: bold;
}

.progressoverviewpage .MuiTableContainer-root.heatmaptableholder{
  max-height: calc(100vh - 50px);
  height: calc(100vh - 50px);
}

.signoffpg.progressoverviewpage thead {
  position: sticky;
  z-index: 9;
}

.MuiTableContainer-root.heatmaptableholder thead th {
  position: sticky;
  top: 98px;
}

.MuiTableContainer-root.heatmaptableholder thead tr:nth-child(2) th{
  position: sticky;
  top: 147px !important;
}

.signoffmenu .MuiPopover-paper{
  background-color: #333847 !important
}

/* .signoff-section .MuiTableContainer-root.heatmaptableholder thead tr:nth-child(2) th{
  min-width: 220px;
} */

.progressoverviewpage tbody tr td:nth-child(1){
  min-width: 200px;
    width: 70%;
}
.signoff-section .editSection{
  display: flex;
  justify-content: center;
  align-items: center;
}

.select-user span{
  color: #fff !important;
  font-size: 0.875rem;

}

.select-user .MuiSvgIcon-root{
  color: #1a1c24 !important;
  /* right: 77px !important; */
}


#menu- .css-3dzjca-MuiPaper-root-MuiPopover-paper-MuiMenu-paper,
#menu- ul{
  background-color: #ffffff !important
}

#menu- a{
  color: #000000 !important;
}

.menubutton{
  background-color: transparent !important;
}

.menubutton label{
  color: #fff !important;
  /* margin-top: -7px; */
}

.menubutton .MuiOutlinedInput-notchedOutline{
  border-color: #fff !important;
}

.menu-button fieldset span{
  padding-left: 32px !important;
}

.menubutton label {
  font-size: 0.875rem;
}

.overflow-ellipsis {
  display: -webkit-box;
  max-width: 500px;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}

.progress-table {
  height: 40px;
}

.progress-row{
  height: fit-content;
    overflow: scroll;
}

.progress-row td{
  overflow: auto;
    height: 170px;
    max-width: 50px !important;
}

.exprtbtn{
  border: 1px solid #7a7a7a80 !important;
  color: rgba(0, 0, 0, 0.87) !important;
  margin-top: 6px !important;
}

.progress-row td::-webkit-scrollbar {
  width: 7px !important;
  height: 10px;
}

.progress-row td::-webkit-scrollbar-thumb { 
  background: #ddd !important;  
  border-radius: 5px;
  border: 2px solid transparent;
}

.techrespage{
    height: calc(100vh - 24px);
    min-height: calc(100vh - 24px);
    overflow: auto;
}

.contentgrid{
  margin-top: 130px;
}

.contentsection{
  margin-top: 50px;
  
}

.contentsection h3{
background-color: #F0F0F0;
padding: 10px;
font-weight: bold;
}

.contentsection p{
  font-weight: 600;
  text-align: center;
  -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    text-overflow: ellipsis;
    overflow: hidden;
    display: -webkit-box;
    width: 200px;
    /* height: 51px; */
    /* display: block; */
    word-wrap: break-word;
    margin: 5px auto 0px;
}

.contentsection.mb-20px{
  margin-bottom: 20px;
}

.contentsection.mb-50px{
  margin-bottom: 50px;
}

.mb-50px{
  margin-bottom: 50px;
}

.mt-30px{
  margin-top: 30px !important;
}

.techrespage .contentsection img{
  border: 5px solid;
}

.card-sis{
  /* box-shadow: 0 4px 24px #22292f1a; */
    /* margin-left: 155px;
    margin-right: 50px; */
    display: flex;
    justify-content: center;
    align-items: center;

}

.card-sis .card-subsection,
.contentsection .w-75{
  width: 75%;
}

.card-head {
  background-color: #F0F0F0;
padding: 10px;


}

.card-head .MuiCardHeader-title{
  font-size: 17px;
  font-weight: 700;
}

.uplbtndiv{
  display: flex;
  justify-content: center;
  margin-top: 23px;
}

.uplbtndiv button{
  background-color: #333847 !important;
  border-color: #333847 !important;
  color: #fff !important;
}

.lasttext{
  margin-bottom: 23px !important;
}

.uplaodform label{
  color: #333847 !important;
  font-weight: 600;
}

.dialog-main .MuiDialog-paper{
  border: 2px solid #ffb764 !important;;
}

.signoffselect{
  display: flex ;
  margin-left: 50px ;
  margin-top: 10px;
  justify-content: end;
}

.signoffselect .MuiInputBase-root,.signoffselect svg,.signoffselect .MuiOutlinedInput-notchedOutline{
  color: #1a1c24 !important;
  border-color: #1a1c24 !important;
}

.signoffselect .MuiInputBase-root{
  height: 40px;
}

.signoffheader{
  margin-top: 10px !important;
}

.signoffselect .menubutton{
  margin: 0px 8px !important;
}

.signoffselect .menubutton label{
  color: #1a1c24 !important;
}

.options-buttondiv {
  display: flex;
    justify-content: center;
    align-items: center;
}

.options-button{
  height: 43.23px;
    margin-top: 5px;
    background-color: #333847 !important;
    border-color: #333847 important;
}

.filtermenu .signoffselect{
  margin-left: 0px !important;
  }
