
@media only screen and (max-width: 767px) {
    .Logocontainer{
        display: none;
    }

    .loginpagemaindiv .MuiPaper-root{
        max-width: 100%;
        margin-top: 50px;
    }

    .defaultdialog .MuiBackdrop-root {
        width: 100%;
        margin-left: auto;
    }

    .defaultdialog .MuiDialog-container {
        width: 90%;
        margin-right: 5%;
        height: 40%;
        margin-top: 40%;
        margin-left: 5%;
    }

    .formviewmaindiv{
        max-width: 100%;
    flex-basis: 100%;
    }

    .thematictab .MuiTabs-flexContainer,
    .thematictabbody .MuiTabs-flexContainer{
        width: 90%;
        overflow: scroll;
    }

    .thematictabbody{
        overflow-x: auto;
    width: 80%;
    
    padding-left:  0px !important;
    }

    .formviewmaindiv{
        max-width: 100% !important;
        flex-basis: 100% !important;
    }

    .navbardiv{
        display: none !important
    }
    
    .loginpagemaindiv{
        padding: 40px 20px;
    }

    .Themetabs{
        max-width: 100%;
        padding-left: 24px;
    }

    .progressoverviewpage .thematictabbody{
        overflow-x: auto;
    width: 100%;
    
    padding-left:  24px !important;
    }
}

@media only screen and (max-width: 1199px) {

.footerbg > div{
    width: 100% !important;
    max-width: 100% !important;
    flex-basis: 100% !important;
}

}
